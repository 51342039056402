
import { defineComponent, reactive, ref, watch, onMounted, computed } from 'vue'
import { createHandler } from '@/services/createHandler'
import { stringRules } from '@/utils/formRules'
import { useRoute } from 'vue-router'
import moment from 'moment'
import { ElMessageBox } from 'element-plus'
import { useFetchData, useNotification } from '@/composables'
import { useResource } from '@/services'
import { formatterDollars } from '@/utils/formatter'
import userSettingsRepositories from '@/repositories/userSettingsRepositories'
import axios from '@/services/axios'

// import { Plus } from '@element-plus/icons'

export default defineComponent({
  props: {
    currentAmount: {
      type: Number,
      default: () => {
        return 0
      },
    },
    seller: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // data: {
    //   type: Object,
    //   default: () => {
    //     return { amount: 0, payWith: 'Payoneer', transactionId: '', note: '' }
    //   },
    // },
  },
  setup(props: any, context: any) {
    let dialogVisible = ref(false)
    let loading = ref(false)
    const depositForm = ref()
    const route = useRoute()
    const { error, success } = useNotification()
    const { getSettings } = userSettingsRepositories()

    const isDisplayExchange = ref(false)
    const total = ref(0)
    const isLoading = ref(true)
    const vnd = ref<any>(23000)
    const usd = ref<any>(1)

    const enableEdit = ref<boolean>(true)

    // Check bambooz => hide exchange rate
    const showExchaneRate = computed(() => {
      if (window.location.host === process.env.VUE_APP_BAMBOOZ_FULFILL_SITE)
        return false
      return true
    })

    const generateNote = (seller: any) => {
      if (!seller) return
      const sellerInfo = `${seller?.name} - ${seller?.manager?.email}`
      const note = `${sellerInfo} - topup at ${moment().format(
        'YYYY MM DD HH mm'
      )}`
      return note
    }

    const state = reactive({
      deposit: {
        id: '',
        amount: 0,
        payWith: ref('Payoneer'),
        transactionId: '',
        note: generateNote(props.seller),
        status: 'pending',
      },
      settings: {
        payment: {
          exchangeRate: 1,
          topupContent: '',
        },
      },
    })

    const onCheckedChange = async () => {
      state.deposit.note = generateNote(props.seller)
    }

    const getSettingData = async () => {
      isLoading.value = true
      const res = (await getSettings()) as any
      if (res?.status === 200 || res?.status === 201) {
        if (res.data?.payment) {
          Object.assign(state.settings.payment, res.data.payment)
        }
      }
    }

    onMounted(async () => {
      await getSettingData()
      onChangeUsd()
    })

    const formRules = {
      name: stringRules('name', 5, 12),
      code: stringRules('name', 5, 12),
    }

    const depositId = ref<any>()
    const action = ref<string>('create')

    const toggle = (actionType: string, deposit?: any, amount?: any) => {
      action.value = actionType
      if (actionType === 'edit' && deposit) {
        depositId.value = deposit.id
        state.deposit.amount = deposit.amount
        state.deposit.payWith = deposit.payWith
        state.deposit.transactionId = deposit.transactionId
        state.deposit.status = deposit.status
        state.deposit.note = deposit.note
      } else {
        clearForm()
      }
      console.log('state.deposit.amount', amount)

      if (amount) {
        state.deposit.amount = amount
      }
      if (state.deposit.status === 'pending') {
        enableEdit.value = true
      } else {
        enableEdit.value = false
      }
      isLoading.value = false

      dialogVisible.value = !dialogVisible.value
    }

    // load deposits muon edit
    // const fetchData = async () => {
    //   const sellerTeamId = info.sellerTeam.id
    //   const resource = `/deposits/seller-teams/${sellerTeamId}/${depositId.value}`

    //   const res = (await axios.get(resource)) as any
    //   if (res?.status === 200 || res?.status === 201) {
    //     const data = res.data
    //     state.deposit.amount = data.amount
    //   }
    // }

    const onChangeUsd = () => {
      vnd.value = (
        usd.value * state.settings.payment.exchangeRate
      ).toLocaleString('it-IT', { style: 'currency', currency: 'VND' })
    }

    const onChangeVnd = () => {
      usd.value = (
        vnd.value / state.settings.payment.exchangeRate
      ).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    }

    const clearForm = () => {
      state.deposit.amount = 0
      state.deposit.payWith = 'Bank transfer'
      state.deposit.transactionId = ''
      state.deposit.note = generateNote(props.seller)

      depositId.value = null
    }

    const onSubmit = async () => {
      // const info = JSON.parse(localStorage.getItem('info') || '')
      let message = `New balance is ${formatterDollars(state.deposit.amount)}`
      if (state.deposit.status === 'pending') {
        message = `Confirm your topup ${formatterDollars(state.deposit.amount)}`
      }
      depositForm.value.validate(async (valid: any) => {
        if (valid) {
          ElMessageBox.confirm(message)
            .then(async () => {
              if (action.value === 'create') {
                createDeposits()
              } else if (action.value === 'edit') {
                editDeposits()
              }
            })
            .catch(err => {
              console.log(err)
              error('Error!')
            })
        }
      })
    }

    const createDeposits = async () => {
      const resource = `deposits/seller-teams`
      state.deposit.amount = Number(state.deposit.amount)
      const { create, isLoading } = createHandler(resource, state.deposit)
      loading = isLoading
      const { status, data } = await create()
      if (status === 201 || status === 200) {
        depositForm.value?.resetFields()
        success('Transaction confirmed')
        dialogVisible.value = false
        clearForm()
        await context.emit('changeBalance')
      } else {
        error(data.message)
      }
    }

    const editDeposits = async () => {
      state.deposit.amount = Number(state.deposit.amount)
      if (state.deposit.status !== 'pending') {
        error('Deposits is completed. You cannot Edit or Delete this')
        return
      }
      const formData = {
        id: depositId.value,
        amount: state.deposit.amount,
        payWith: state.deposit.payWith,
        transactionId: state.deposit.transactionId,
        status: state.deposit.status,
        note: state.deposit.note,
      }
      const resource = `deposits/${depositId.value}`
      const res = (await axios.patch(resource, formData)) as any
      if (res.status === 201 || res.status === 200) {
        depositForm.value?.resetFields()
        success('Update success')
        dialogVisible.value = false
        clearForm()
        await context.emit('changeBalance')
      } else {
        error(res.data.message)
      }
    }

    const getTypeOfStatus = (status: string) => {
      if (status) {
        if (status === 'approved') {
          return 'success'
        }
        if (status === 'pending') {
          return 'warning'
        }
        if (status === 'rejected') {
          return 'error'
        }
      }
      return 'info'
    }

    const onChangeDisplayExchange = () => {
      isDisplayExchange.value = !isDisplayExchange.value
    }

    const onChangeBalance = (newBalance: any) => {
      total.value = parseFloat(props.currentAmount) + parseFloat(newBalance)
    }

    const watchTotal = watch(props, () => {
      total.value = props.currentAmount
    })

    const closeHandle = () => {
      dialogVisible.value = false
      clearForm()
      depositForm.value?.resetFields()
    }

    return {
      depositId,
      state,
      depositForm,
      formRules,
      onSubmit,
      onCheckedChange,
      dialogVisible,
      loading,
      toggle,
      total,
      isLoading,
      onChangeBalance,
      watchTotal,
      closeHandle,
      formatterDollars,
      vnd,
      usd,
      onChangeVnd,
      onChangeUsd,
      getTypeOfStatus,
      createDeposits,
      editDeposits,
      action,
      enableEdit,
      onChangeDisplayExchange,
      isDisplayExchange,
      generateNote,
      showExchaneRate
    }
  },
})
