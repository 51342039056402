import axios from '@/services/axios'

export default function userSettingsRepositories() {
  const resource = `/agencies/settings`

  const getSettings = async () => {
    try {
      return await axios.get(resource)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getContactSetting = async () => {
    try {
      const res = (await axios.get(resource)) as any
      return res.data?.contact?.shippingTitle
        ? res.data.contact.shippingTitle
        : '#'
    } catch (error) {
      console.log(error)
      return '#'
    }
  }

  return {
    getSettings,
    getContactSetting,
  }
}
